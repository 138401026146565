<template>
  <vs-card
    :id="`bot-${bot.id}`"
    class="bot-item cardx"
    :class="{
      'bot-disabled': bot.legacy,
      selected: botSelected && botSelected === bot.id
    }"
    actionable
  >
    <vs-popup
      :title="lang.botMaker.botsView.whatsApp.popup.title[languageSelected]"
      :active.sync="whatsappPrompt"
    >
      <span>
        {{ lang.botMaker.botsView.whatsApp.popup.text[languageSelected] }}
      </span>
      <vs-input
        :placeholder="
          lang.botMaker.botsView.whatsApp.popup.placeholder[languageSelected]
        "
        :vs-placeholder="
          lang.botMaker.botsView.whatsApp.popup.placeholder[languageSelected]
        "
        v-model="whatsappPromptPhone"
        class="w-full mt-2"
      />
      <vs-button class="mt-3 float-right" @click="configureWhatsAppProxy">
        {{
          lang.botMaker.botsView.whatsApp.popup.button.accept[languageSelected]
        }}
      </vs-button>
    </vs-popup>

    <div class="bot-item-content" @click.self="onBotClick">
      <div class="bot-item-body" @click="onBotClick">
        <div
          v-if="botSelected && botSelected === bot.id"
          class="bot-item-body__logo svg"
        ></div>
        <img
          v-else
          class="bot-item-body__logo img"
          src="@/assets/images/icons/channels/all.svg"
          alt
        />
        <div class="bot-item-body__header">
          <h3 class="bot-name">{{ bot.service.name }}</h3>
          <p class="bot-description" v-show="bot.service.description">
            {{ bot.service.description }}
          </p>
        </div>
      </div>

      <!-- bot actions -->
      <div v-if="showActions" class="bot-item-footer" @click.self="onBotClick">
        <!-- <vs-button
          size="small"
          class="bot-item-btn"
          icon="icon-message-square"
          icon-pack="feather"
          v-tooltip.bottom="
            lang.botMaker.botsView.tooltip.whatsApp[languageSelected]
          "
          @click="whatsappPrompt = true"
          :disabled="isTestBtnDisabled"
        ></vs-button>-->
        <vs-button
          size="small"
          class="bot-item-btn"
          icon="icon-play"
          icon-pack="feather"
          v-tooltip.bottom="
            lang.botMaker.botsView.tooltip.test[languageSelected]
          "
          @click="testBot(bot.id, bot.service.id)"
          :data-cy="`test-${bot.service.name.toLowerCase().replace(' ','')}`"
          :disabled="isTestBtnDisabled"
        ></vs-button>
        <vs-button
          size="small"
          class="bot-item-btn"
          icon="icon-settings"
          icon-pack="feather"
          v-tooltip.bottom="
            lang.botMaker.botsView.tooltip.edit[languageSelected]
          "
          :data-cy="`edit-${bot.service.name.toLowerCase().replace(' ','')}`"          
          @click="navigateToAddBot"
          :disabled="isSettingsBtnDisabled"
        ></vs-button>
        <vs-button
          size="small"
          class="bot-item-btn"
          icon="icon-trash"
          icon-pack="feather"
          v-tooltip.bottom="
            lang.botMaker.botsView.tooltip.delete[languageSelected]
          "
          :data-cy="`delete-${bot.service.name.toLowerCase().replace(' ','')}`"
          @click="deleteBot(bot.id)"
          :disabled="isDeleteBtnDisabled"
        ></vs-button>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import BotMakerService from '../../../../../services/botMaker.service'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'BotItem',
  props: {
    bot: Object,
    showActions: {
      type: Boolean,
      default: true
    },
    selectBot: Function,
    botSelected: String,
    isNewChat: {
      type: Boolean,
      default: false
    },
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      whatsappPrompt: false,
      whatsappPromptPhone: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    isTestBtnDisabled() {
      if (this.bot.legacy) {
        return true
      }

      if (this.isAuthAux('konecta.bots.try_bot', false)) {
        return false
      }

      return !this.session.user.roles.canEditBot.testBot
    },
    isSettingsBtnDisabled() {
      if (this.useStaffManagement && !this.isAuthAux('konecta.bot_maker.edit_bot_settings', false)) {
        return true
      }
      return false
    },
    isDeleteBtnDisabled() {
      if (this.useStaffManagement && !this.isAuthAux('konecta.bot_maker.delete_bot', false)) {
        return true
      }
      return false
    },
    botClasses() {
      if (this.bot.legacy) {
        return 'bot-icon bot-disabled'
      }

      return 'bot-icon'
    }
  },
  methods: {
    configureWhatsAppProxy() {
      this.$log.info('Bot ID', this.bot.id)
      this.$log.info('To', this.whatsappPromptPhone)
      BotMakerService.configureWhatsAppProxy(
        this.bot.id,
        this.whatsappPromptPhone
      )
        .then(() => {
          this.$vs.notify({
            title: this.lang.botMaker.botsView.whatsApp.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.botsView.whatsApp.text[
              this.languageSelected
            ],
            color: 'success',
            position: 'top-right'
          })
          this.whatsappPrompt = false
        })
        .catch(() => {
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'warning',
            position: 'top-right'
          })
          this.whatsappPrompt = false
        })
    },
    ...mapMutations('botMaker', ['SET_BOT']),
    ...mapActions('bots', [
      'DELETE_BOT',
      'GET_VERSION_INTENTS'
    ]),
    onBotClick() {
      if (!this.isNewChat
          && this.useStaffManagement
          && !this.isAuthAux('konecta.bot_maker.edit_bot', false)
          && !this.isAuthAux('konecta.bot_maker.view_bot', false)) {
        return null
      }

      if (this.selectBot) {
        this.selectBot(this.bot)
      } else {
        this.navigateToEditBot()
      }
    },
    navigateToAddBot() {
      let botName = this.bot.service.name
      let botId = this.bot.id
      let serviceId = this.bot.service.id
      this.SET_BOT(this.bot)

      this.$router.push({
        path: '/bot-maker/bot/' + botName + '/edit',
        query: { botId: botId, serviceId: serviceId }
      })
    },
    navigateToEditBot() {
      if (!this.bot.legacy && !this.bot.bypass) {
        let botName = this.bot.service.name
        let botId = this.bot.id
        let serviceId = this.bot.service.id
        this.SET_BOT(this.bot)

        this.$router.push({
          path: '/bot-maker/bot/' + botName,
          query: { botId: botId, serviceId: serviceId }
        })
      }
    },
    deleteBot(botId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.botMaker.botsView.deleteBotWarning.title[
          this.languageSelected
        ],
        text: this.lang.botMaker.botsView.deleteBotWarning.text[
          this.languageSelected
        ],
        acceptText: this.lang.botMaker.accept[this.languageSelected],
        cancelText: this.lang.botMaker.cancel[this.languageSelected],
        accept: () => {
          this.$vs.loading()
          this.DELETE_BOT(botId)
            .then(() => {
              this.$vs.notify({
                color: 'success',
                text: this.lang.botMaker.botsView.notifications
                  .deleteBotSuccess[this.languageSelected]
              })
            })
            .catch(error => {
              this.$log.error(error)
              this.$vs.notify({
                color: 'danger',
                text: this.lang.botMaker.botsView.notifications
                  .deleteBotSuccess[this.deleteBotError]
              })
            })
            .finally(() => {
              this.$vs.loading.close()
            })
        }
      })
    },
    async testBot(botId, serviceId) {
      try {
        this.$vs.loading()
        const versionResponse = await BotMakerService.getActiveVersion(
          serviceId
        )
        const versionId = versionResponse.data[0]._id
        const conditionsResponse = await BotMakerService.getDialogs(versionId)
        const conditions = conditionsResponse.data

        if (conditions.length >= 2) {
          let routeData = this.$router.resolve({
            path: '/bot-maker/bot/' + botId + '/test',
            query: {
              serviceId: this.bot.service.id,
              botName: this.bot.service.name
            }
          })

          window.open(routeData.href, '_blank')
        } else {
          this.$vs.dialog({
            color: 'warning',
            title: this.lang.botMaker.botEditor.navBar.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.botEditor.navBar.text[
              this.languageSelected
            ],
            acceptText: this.lang.botMaker.accept[this.languageSelected]
          })
        }
      } catch (error) {
        this.$log.error(error)
      }

      this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.bot-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 10px;

  .vs-card--content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .bot-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    user-select: none;
    overflow: hidden;

    &:hover {
      overflow: unset;
    }

    .bot-item-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &:hover {
        overflow: unset;
      }

      &__logo.img {
        width: 25px;
        transition: all 0.2s ease-in-out;
      }

      &__header {
        padding: 25px 0;
        flex-grow: 1;

        .bot-name {
          font-size: 24px;
          font-style: normal;
          font-weight: normal;
          line-height: 26px;
          letter-spacing: -1px;
          margin: 0;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bot-description {
          font-size: 12px;
          font-style: normal;
          line-height: 20px;
          color: #afafaf;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media (max-width: 768px) {
          .bot-name {
            font-size: 16px;
          }
          .bot-description {
            display: none;
          }
        }
      }
    }

    .bot-item-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .bot-item-btn {
        width: 33px !important;
        height: 33px !important;
        background: white !important;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        border: none;
        transition: all 0.2s ease-in-out;

        &:not(:last-of-type) {
          margin-right: 5px;
        }

        &:hover {
          transform: scale(1.05);
        }

        .vs-icon {
          color: rgba(var(--vs-primary), 1) !important;
        }
      }
    }
  }

  &.selected {
    background: rgba(var(--vs-primary), 1) !important;

    .bot-item-content {
      .bot-item-body {
        &__logo.svg {
          mask: url(../../../../../assets/images/icons/channels/all.svg);
          -webkit-mask: url(../../../../../assets/images/icons/channels/all.svg);
          background-size: cover;
          -webkit-mask-size: contain;
          mask-size: contain;
          -webkit-mask-position: center;
          mask-position: center;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          background-color: #fafafa;
          min-height: 29.67px;
          min-width: 25px;
          max-height: 29.67px;
          max-width: 25px;
          transition: all 0.2s ease-in-out;
        }

        &__header {
          .bot-name,
          .bot-description {
            color: #fafafa;
          }
        }
      }
    }
  }
}
</style>
