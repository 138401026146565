import { mapState } from 'vuex'

const { isAuthorized } = require('../staffManagement/viewPermissionMapping');

export default {
  computed: {
    ...mapState(['session', 'useStaffManagement'])
  },
  methods: {
    isAuthAux(key, ifNoStaffManagement=true) {
      if (!this.useStaffManagement) {
        return ifNoStaffManagement
      }
      const roles = this.session && this.session.roles
      return isAuthorized({ key, permissions: roles })
    },
    isAuthorized({ key, permissions }) {
      if (permissions === undefined) {
        const roles = this.session && this.session.roles
        return isAuthorized({ key, permissions: roles })
      }
      return isAuthorized({ key, permissions })
    }
  }
}
