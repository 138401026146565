<template>
  <vs-card id="search-bar" class="search-bar">
    <vs-input
      icon-no-border
      icon="search"
      :placeholder="lang.botMaker.botsView.search[languageSelected]"
      vs-type="flex"
      vs-xs="12"
      vs-sm="6"
      vs-lg="3"
      class="search-bar__input"
      v-model="search"
      data-cy="searchBot"
    />
  </vs-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'SearchBar',
  computed: {
    ...mapState(['lang', 'languageSelected']),
    search: {
      get() {
        return this.$store.state.bots.filters.search
      },
      set(value) {
        this.UPDATE_SEARCH_FILTER(value)
      }
    }
  },
  methods: {
    ...mapMutations('bots', ['UPDATE_SEARCH_FILTER'])
  }
}
</script>

<style lang="scss">
.search-bar {
  background: transparent;
  box-shadow: none;
  display: flex;
  justify-content: unset;
  flex-grow: 1;
  width: auto;
  margin-bottom: 0;

  .vs-card--content {
    display: flex;
    flex-grow: 1;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  &__input {
    width: 55%;
    &.vs-input-primary {
      .vs-con-input .vs-input--input {
        border: none !important;
        border-bottom: 1px solid rgba(var(--vs-primary), 1) !important;
        background: transparent;
        border-radius: 0;

        &:focus {
          border: none !important;
          border-bottom: 1px solid rgba(var(--vs-primary), 1) !important;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
