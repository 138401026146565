<template>
  <div class="bots" id="bots">
    <div>
      <vs-row>
        <!-- Main component -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-xs="12"
          vs-sm="12"
          vs-lg="12"
        >
          <vs-row>
            <vs-col>
              <div class="bots__header">
                <!-- Search bar -->
                <SearchBar />

                <!-- Add bot -->
                <vs-button
                  v-if="canAccess"
                  class="float-right"
                  @click="navigateToAddBot"
                  data-cy="addBot"
                  id="add_bot"
                >
                  {{ lang.botMaker.addBot.startDevelop[languageSelected] }}
                </vs-button>
              </div>
            </vs-col>

            <!-- Bots -->
            <vs-col
              :key="index"
              v-for="(bot, index) in botsFiltered"
              vs-type="flex"
              vs-justify="center"
              vs-align="stretch"
              vs-xs="12"
              vs-sm="6"
              vs-lg="3"
              class="all-bots-view"
              data-cy="bots"
            >
              <BotItem :bot="bot" />
            </vs-col>
            <span v-if="botsFiltered.length === 0" class="ml-4">
              {{ lang.general.noDataText[languageSelected] }}
            </span>
          </vs-row>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BotItem from './bots/BotItem'
import SearchBar from './bots/SearchBar'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  components: {
    BotItem,
    SearchBar
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      tryBot: false,
      botSelected: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', ['getBots', 'filters']),
    botsFiltered() {
      let tmpArray = this.getBots

      // Filter by search bar
      if (this.filters && this.filters.search) {
        tmpArray = tmpArray.filter(bot => {
          let botName = bot.service.name.toLowerCase()
          return botName.includes(this.filters.search.toLowerCase())
        })
      }

      return tmpArray
    },
    canAccess() {
      if (this.useStaffManagement) {
        return this.isAuthAux('konecta.bots.add')
      }
      return (
        this.session &&
        this.session.user &&
        this.session.user.roles.canAccess.botMaker
      )
    }
  },
  methods: {
    ...mapActions('bots', ['GET_BOTS']),
    navigateToAddBot() {
      this.$router.push('/bot-maker/add')
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$vs.loading({
      container: '.bots',
      scale: 1
    })
    this.GET_BOTS().finally(() => {
      this.$vs.loading.close('.bots > .con-vs-loading')
    })
  }
}
</script>
<style lang="scss" scoped>
.bots {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .center {
    text-align: center;
  }

  .add-bot-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
    span {
      margin-right: 15px;
      font-weight: bold;
    }
  }
}
</style>
