import { render, staticRenderFns } from "./Bots.vue?vue&type=template&id=494ebb90&scoped=true&"
import script from "./Bots.vue?vue&type=script&lang=js&"
export * from "./Bots.vue?vue&type=script&lang=js&"
import style0 from "./Bots.vue?vue&type=style&index=0&id=494ebb90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494ebb90",
  null
  
)

export default component.exports